import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Title from "../components/title"

const About = () => {
  return (
    <Layout>
      <Title text="ABOUT ME" subtitle="" />
      <div>
        <Link to="/">Home</Link> | <Link to="/about">About</Link>
      </div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Sed id semper risus
        in hendrerit gravida rutrum quisque. Et netus et malesuada fames ac
        turpis egestas. In metus vulputate eu scelerisque felis imperdiet proin
        fermentum leo. A lacus vestibulum sed arcu non. Commodo odio aenean sed
        adipiscing diam donec adipiscing. Maecenas ultricies mi eget mauris.
        Amet consectetur adipiscing elit duis tristique sollicitudin nibh. At in
        tellus integer feugiat scelerisque varius morbi enim nunc. Morbi
        tristique senectus et netus et.
      </p>
      <p>
        Eu nisl nunc mi ipsum. Ultrices neque ornare aenean euismod elementum.
        Fringilla ut morbi tincidunt augue interdum velit. Leo duis ut diam
        quam. Purus semper eget duis at tellus at urna condimentum mattis.
        Tortor aliquam nulla facilisi cras fermentum odio. Nisi porta lorem
        mollis aliquam ut porttitor. Amet nisl purus in mollis nunc sed id
        semper risus. Sodales ut eu sem integer vitae justo eget magna. Sit amet
        consectetur adipiscing elit ut aliquam purus sit. Vitae tempus quam
        pellentesque nec nam aliquam. Quisque egestas diam in arcu cursus
        euismod quis viverra nibh. Nibh venenatis cras sed felis. Massa id neque
        aliquam vestibulum morbi. Amet massa vitae tortor condimentum. Lacinia
        quis vel eros donec ac odio. Risus in hendrerit gravida rutrum quisque
        non.
      </p>
      <p>
        Ultricies lacus sed turpis tincidunt. Blandit cursus risus at ultrices
        mi tempus imperdiet nulla. Ullamcorper sit amet risus nullam eget felis
        eget nunc. At urna condimentum mattis pellentesque id nibh. A erat nam
        at lectus urna duis convallis convallis tellus. Phasellus vestibulum
        lorem sed risus ultricies tristique nulla aliquet. A erat nam at lectus
        urna duis convallis convallis tellus. Non enim praesent elementum
        facilisis leo. Euismod lacinia at quis risus sed vulputate odio ut.
        Pellentesque dignissim enim sit amet venenatis. Cras tincidunt lobortis
        feugiat vivamus at augue eget arcu. Vitae turpis massa sed elementum
        tempus egestas sed sed. Scelerisque eu ultrices vitae auctor eu augue ut
        lectus. Urna molestie at elementum eu. Posuere ac ut consequat semper
        viverra nam libero justo. Enim nunc faucibus a pellentesque sit amet
        porttitor eget. Sed viverra tellus in hac habitasse platea. Eu non diam
        phasellus vestibulum lorem sed risus ultricies tristique.
      </p>
    </Layout>
  )
}

export default About;